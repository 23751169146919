<template>
  <div class="auth-form-wrapper">
    <div class="auth-form-container">
      <div class="auth-form">
        <ShowTitle text="電子郵件驗證" type="title" />
        <i
          @click="$router.push({ path: '/' })"
          class="
            auth-close-btn
            pointer
            el-icon-close
            basic-hover
            ease-in-out-03s
            font-gray
          "
        ></i>
        <el-form>
          <el-form-item>
            <el-input
              style="width: 260px"
              placeholder="驗證碼"
              type="email"
              v-model="dataObj.code"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="form-button-container">
          <el-button
            class="ease-in-out-03s basic-submit-btn"
            :loading="submitLoading"
            @click="HandleSubmit()"
          >
            確認
          </el-button>
          <div class="margin-top-xl margin-bottom-xs">沒有收到驗證碼？</div>
          <a
            class="ease-in-out-03s basic-hover font-blue pointer"
            @click="HandleEmailResend()"
            >重新寄送驗證碼
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";
import { userEmailVerify, userEmailVerifyResend } from "@/api/user";
import Cookies from "js-cookie";

export default {
  mixins: [mainCommon],
  data() {
    return {
      submitLoading: false,
      dataObj: {
        code: null,
      },
    };
  },
  created() {
    this.HandleAuthorized();
  },
  methods: {
    HandleSubmit() {
      this.submitLoading = true;
      userEmailVerify({
        code: this.dataObj.code,
        email: Cookies.get("emailVerify"),
      })
        .then((res) => {
          Cookies.remove("emailVerify");
          this.pushNotification(res.message);
          let token = res.data.token;
          localStorage.setItem("authorization", token.slice(0, 30));
          Cookies.set("authorization", token.slice(30, 64));
          setTimeout(() => {
            location.href = "/";
          }, 500);
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    HandleEmailResend() {
      userEmailVerifyResend({
        email: this.dataObj.email || Cookies.get("emailVerify"),
      }).then((res) => {
        this.pushNotification(res.message);
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-input .el-input__inner {
  padding: 0 15px;
}
</style>